<script lang="ts" setup>
import { NonEmptyString255 } from "@effect-app/schema"
import { TickersRsc } from "@getsignalz/api/resources"
import { mdiArrowLeft, mdiMagnify } from "@mdi/js"

defineProps<{
  forceFullSize?: boolean
}>()

const router = useRouter()

const query = ref<string>("")
const queryToSearch = ref<string>("")

const { isSmall } = useDevice()

const tickersClient = clientFor(TickersRsc)

const wrappedSearch = {
  handler: (query: string) =>
    query
      ? tickersClient.Search.handler({ query: NonEmptyString255(query) })
      : tickersClient.Highlights.handler,
  name: tickersClient.Search.name,

  mapPath: (query: string) =>
    query
      ? tickersClient.Search.mapPath({ query: NonEmptyString255(query) })
      : tickersClient.Highlights.mapPath,
}

const [searchQuery, searchRes] = useSafeQuery(wrappedSearch, queryToSearch, {
  refetchOnWindowFocus: false,
})

const securities = computed(() =>
  searchRes.value ? searchRes.value.items : [],
)

const hasNoResultsForQuery = computed(() =>
  searchRes.value ? searchRes.value.items.length === 0 : false,
)

let debounceTimeout: number | undefined

watch(query, () => {
  clearTimeout(debounceTimeout)
  debounceTimeout = window.setTimeout(() => {
    queryToSearch.value = query.value
  }, 200)
})

const isDialogOpen = ref(false)

const isActive = ref<boolean>(false)

const goToSecurity = (symbol: string) => {
  router.push("/securities/" + symbol)
  isActive.value = false
}
</script>

<template>
  <v-autocomplete
    v-if="isSmall || forceFullSize"
    :active="isActive"
    clearable
    color="primary"
    :items="
      securities.map(el => ({
        title: el.name,
        value: el.id,
        ...el,
      }))
    "
    no-filter
    v-model:search="query"
    v-model:menu="isActive"
    :label="trans('body.searchSecurity')"
    hide-details
    aria-autocomplete="none"
    class="search-security rounded-xl"
    :loading="searchQuery.waiting"
    density="compact"
    :prepend-inner-icon="mdiMagnify"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item
        v-if="!hasNoResultsForQuery"
        v-bind:props="props"
        :title="item.title"
        @click="() => goToSecurity(item.value)"
      >
        <template v-slot:title="{ title }">
          {{ title }}
        </template>
        <template v-slot:subtitle>
          <b>{{ item.raw.symbol }}</b>
          {{ item.raw.stockExchange.name }}
          <Flag :country-code="item.raw.stockExchange.countryCode" />
        </template>
      </v-list-item>
    </template>
    <template v-slot:no-data>
      <v-list-item
        class="text-grey"
        :title="trans('text.no_signals_or_security')"
      ></v-list-item>
    </template>
  </v-autocomplete>

  <v-dialog v-else :fullscreen="true" v-model="isDialogOpen">
    <template v-slot:activator>
      <v-btn
        @click="isDialogOpen = true"
        :icon="mdiMagnify"
        variant="flat"
      ></v-btn>
    </template>

    <template v-slot:default>
      <v-card>
        <v-card-title class="d-flex">
          <v-spacer />
          <v-btn
            flat
            class="rounded-lg px-0"
            size="small"
            @click="isDialogOpen = false"
          >
            <template v-slot:prepend>
              <v-icon :icon="mdiArrowLeft"></v-icon>
            </template>
            {{ trans("body.back") }}
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-autocomplete
            color="primary"
            :items="
              securities.map(el => ({
                title: el.name,
                value: el.symbol,
                ...el,
              }))
            "
            no-filter
            v-model:search="query"
            :label="trans('body.searchSecurity')"
            hide-details
            aria-autocomplete="none"
            class="search-security rounded-xl"
            :loading="searchQuery.waiting"
            density="compact"
            :prepend-inner-icon="mdiMagnify"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-bind:props="props"
                :title="item.title"
                @click="
                  () => {
                    goToSecurity(item.value)
                    isDialogOpen = false
                  }
                "
              >
                <template v-slot:title="{ title }">
                  {{ title }}
                </template>
                <template v-slot:subtitle>
                  <b>{{ item.raw.symbol }}</b>
                  {{ item.raw.stockExchange.name }}
                  <Flag :country-code="item.raw.stockExchange.countryCode" />
                </template>
              </v-list-item>
            </template>
            <template v-slot:no-data>
              <v-list-item
                :title="trans('text.no_signals_or_security')"
              ></v-list-item>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
